import {FormikProps} from 'formik'
import {TextInput} from '../form'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    prop: FormikProps<any>
    label?: string
    placeholder?: string
    name?: string
    colSize?: string
}

export function SearchName({
    prop,
    label = 'Nome',
    name = 'name',
    placeholder = 'Entre com o nome',
    colSize = '3',
}: Props) {
    return (
        <div className={`col-12 col-lg-${colSize} mb-5`}>
            <TextInput
                label={label}
                placeholder={placeholder}
                id='name'
                name={name}
                formik={prop}
            />
        </div>
    )
}
