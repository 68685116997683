import axios from 'axios'
import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import {DatatableElicerModel} from '../models'
import {ButtonSubmit} from '../../../widgets/form'
import {parseMoneyDotted} from '../../../utils'
interface Props {
    onClose: (isOpen: boolean) => void
    elicer: DatatableElicerModel
    onUpdate: () => void
    isOpen: boolean
}

export default function ResendEmailElicerModal({onClose, isOpen, elicer, onUpdate}: Props) {
    const [loading, setLoading] = useState(false)

    async function handleUpdate() {
        try {
            setLoading(true)
            const {data} = await axios.put(
                `/unauthorized/products/events/elicer/${elicer.id}/resend-email`
            )
            console.log(data)
            toast.success(data.message)
            onUpdate()
            onClose(false)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Modal
            onHide={() => onClose(false)}
            show={isOpen}
            aria-labelledby='contained-modal-title-center'
            backdrop='static'
            // size='sm'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-center'>Reenviar e-mail?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className='fw-bold'>
                    Ao confirmar, você envia novamente o e-mail de comprovante com o QR-Code do
                    cartão para:
                    <br />
                    <br />
                    <strong>Nome: </strong>
                    {elicer.name}
                    <br />
                    <strong>E-mail: </strong>
                    {elicer.email}
                    <br />
                    <strong>CPF: </strong>
                    {elicer.document}
                    <br />
                    <strong>Valor: </strong>
                    {parseMoneyDotted(elicer.vn_credit)}
                    <br />
                    <br />
                    <strong>Essa ação será auditada.</strong>
                </p>

                <ButtonSubmit
                    loading={loading}
                    label={'Confirmar'}
                    onCancel={() => onClose(false)}
                    isSubmit={false}
                    onConfirmClick={() => handleUpdate()}
                />
            </Modal.Body>
        </Modal>
    )
}
