import {Formik} from 'formik'
import {useState} from 'react'
import {DefaultUnauthorizedContainer} from '../../widgets/containers/DefaultUnauthorizedContainer'
import {DefaultCardWithTitleContainer} from '../../widgets/containers/DefaultCardWithTitleContainer'
import {SearchName} from '../../widgets/datatable/SearchName'
import {ButtonSearch} from '../../widgets/datatable/ButtonSearch'
import DataTables from '../../widgets/datatable'
import {DatatableElicerFilter, DatatableElicerModel} from './models'
import {parseMoneyDotted} from '../../utils'
import {SearchMinMoney} from '../../widgets/datatable/SearchMinMoney'
import {SearchMaxMoney} from '../../widgets/datatable/SearchMaxMoney'
import {SearchDateAt} from '../../widgets/datatable/SearchDateAt'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Tooltip} from '../../widgets/tooltips/Tooltip'
import ResendEmailElicerModal from './modals/ResendEmailElicerModal'

export default function DatatableElicer() {
    /*Variaveis para modal*/
    const [filters, setFilters] = useState<DatatableElicerFilter>()
    const [isOpenResendEmail, setIsOpenResendEmail] = useState(false)
    const [selected, setSelected] = useState<DatatableElicerModel>()

    const initialValues = {
        name: '',
        document: '',
        phone: '',
        email: '',
        min_money: '',
        max_money: '',
        date_at: '',
    }

    return (
        <DefaultUnauthorizedContainer maxWidth='100%'>
            <div className='card-header'>
                <div className='my-5 text-center'>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo-kachin-unauthorized.png')}
                        className='h-60px'
                    />
                </div>
            </div>

            <DefaultCardWithTitleContainer title='Elicer - Circuito Literário'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        setFilters({
                            name: values.name,
                            document: values.document,
                            email: values.email,
                            phone: values.phone,
                            min_money: values.min_money.substring(2),
                            max_money: values.max_money.substring(2),
                            date_at: values.date_at,
                        })
                    }}
                >
                    {(props) => {
                        const {handleChange, handleBlur, handleSubmit} = props
                        return (
                            <form onSubmit={handleSubmit} id='kt_signin_change_password' noValidate>
                                <div className='row'>
                                    <SearchName
                                        prop={props}
                                        label='Nome'
                                        placeholder='Entre com o nome'
                                    ></SearchName>
                                    <SearchName
                                        prop={props}
                                        label='Documento'
                                        name='document'
                                        placeholder='Entre com o documento'
                                    ></SearchName>
                                    <SearchName
                                        prop={props}
                                        label='E-mail'
                                        name='email'
                                        placeholder='Entre com o e-mail'
                                    ></SearchName>
                                    <SearchName
                                        prop={props}
                                        label='Telefone'
                                        name='phone'
                                        placeholder='Entre com o telefone'
                                    ></SearchName>

                                    <SearchMinMoney onChange={handleChange} onBlur={handleBlur} />
                                    <SearchMaxMoney onChange={handleChange} onBlur={handleBlur} />
                                    <SearchDateAt
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    ></SearchDateAt>
                                    <ButtonSearch></ButtonSearch>
                                </div>
                            </form>
                        )
                    }}
                </Formik>
                <DataTables
                    configs={{
                        head: [
                            '#',
                            'Valor',
                            'Nome',
                            'E-mail',
                            'Telefone',
                            'Documento',
                            'Nº Cartão',
                            'Cód. Transação',
                            'Data Webhook',
                            'Data Kachin',
                            '#',
                        ],
                        keys: [
                            'id',
                            'vn_credit',
                            'name',
                            'email',
                            'phone',
                            'document',
                            'card_id',
                            'event_purchase_id',
                            'webhook_date',
                            'created_at',
                            'action',
                        ],
                        url: '/unauthorized/products/events/elicer/datatable',
                        blockOrder: [
                            'card_id',
                            'event_purchase_id',
                            'webhook_date',
                            'created_at',
                            'action',
                        ],
                        centeredIndex: [0, 1, 4, 5, 6, 7, 8, 9, 10],
                        reverseOrder: true,
                    }}
                    filters={filters}
                    components={[
                        {
                            target: 1,
                            component: (item) => (
                                <span className='fw-bolder text-dark'>
                                    {parseMoneyDotted(item.vn_credit)}
                                </span>
                            ),
                        },
                        {
                            target: 10,
                            component: (obj) => (
                                <div className='btn-group'>
                                    <Tooltip text='Reenviar e-mail' placement='top'>
                                        <button
                                            onClick={() => {
                                                setSelected(obj)
                                                setIsOpenResendEmail(true)
                                            }}
                                            className='btn btn-icon btn-light-warning btn-sm'
                                        >
                                            <i className='fas fa-envelope' />
                                        </button>
                                    </Tooltip>
                                </div>
                            ),
                        },
                    ]}
                />
                {selected !== undefined && (
                    <ResendEmailElicerModal
                        isOpen={isOpenResendEmail}
                        onClose={() => setIsOpenResendEmail(false)}
                        elicer={selected}
                        onUpdate={() => {
                            setSelected(undefined)
                        }}
                    />
                )}
            </DefaultCardWithTitleContainer>
        </DefaultUnauthorizedContainer>
    )
}
