import axios from 'axios'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Spinner, Table} from 'react-bootstrap-v5'
import Pagination from '../pagination'
import {PropsDataTable, RequestTable} from './models'

export default function DataTables({
    configs: {
        head,
        keys,
        blockOrder,
        initOrderIndex = 0,
        reverseOrder = false,
        url,
        refresh,
        centeredIndex,
        orderByKey,
    },
    filters,
    components,
}: PropsDataTable) {
    const [orderBy, setOrderBy] = useState(initOrderIndex)
    const [orderIn, setOrderIn] = useState(reverseOrder)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(5)
    const [nPage, setNPage] = useState(20)
    const [data, setData] = useState<RequestTable>()
    const constProps = useRef({
        head,
        keys,
        blockOrder,
        initOrderIndex,
        reverseOrder,
        url,
        centeredIndex,
        orderByKey,
    })
    const filterData = useRef(filters)

    const getData = useCallback(async () => {
        setLoading(true)
        const {data} = await axios.post<RequestTable>(constProps.current.url, {
            draw: 1,
            start: nPage * page,
            length: nPage,
            order: [
                {
                    column: constProps.current.orderByKey || constProps.current.keys[orderBy],
                    dir: orderIn ? 'desc' : 'asc',
                },
            ],
            ...filterData.current,
        })
        setMaxPage(Math.ceil(data?.data.datatable.iTotalDisplayRecords / nPage))
        setData(data)
        setLoading(false)
    }, [nPage, orderBy, orderIn, page])

    if (JSON.stringify(filterData.current) !== JSON.stringify(filters)) {
        filterData.current = filters
        setPage(0)
        getData()
    }

    useEffect(() => {
        getData()
    }, [getData, refresh, filterData])

    const styleButton = {
        background: 'none',
        border: 'none',
        margin: 0,
        padding: 0,
    }

    function selectColumn(index: number) {
        if (index === orderBy) {
            setOrderIn(!orderIn)
        } else {
            setOrderBy(index)
            setOrderIn(false)
        }
    }

    return (
        <>
            <Table
                responsive
                bordered
                className='table table-hover table-row-dashed table-col-dashed align-middle table-rounded'
                width='100%'
            >
                <thead>
                    <tr className='fw-bolder text-muted bg-light'>
                        {head.map((th: String, index: number) => (
                            <th
                                key={'head-' + th}
                                className={`text-nowrap ${
                                    centeredIndex?.some((item) => item === index) && 'text-center'
                                }`}
                            >
                                <button
                                    className=''
                                    style={{
                                        ...styleButton,
                                        ...(orderBy === index ? {border: 'blue'} : {}),
                                        ...(blockOrder?.some((item) => item === keys[index]) && {
                                            cursor: 'default',
                                        }),
                                    }}
                                    onClick={() =>
                                        blockOrder?.some((item) => item === keys[index])
                                            ? {}
                                            : selectColumn(index)
                                    }
                                >
                                    {th}
                                    {!blockOrder?.some((item) => item === keys[index]) && (
                                        <i
                                            className={`fas fa-sort-amount-${
                                                orderIn && orderBy === index ? 'up' : 'down-alt'
                                            }
                                    text-${orderBy === index ? 'primary' : 'gray'}
                                    ms-2`}
                                        ></i>
                                    )}
                                </button>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={head.length} className='text-center text-primary h-100px'>
                                <Spinner animation='grow' />
                            </td>
                        </tr>
                    ) : data?.data.datatable.data.length ? (
                        data.data.datatable.data.map((obj: any, index: number) => (
                            <tr key={'itemTable-' + index}>
                                {head.map((key, i) =>
                                    components && components?.some((item) => i === item.target) ? (
                                        <td
                                            key={index + '-' + i}
                                            className={`
                                                    ${
                                                        components[
                                                            components.findIndex(
                                                                (a) => a.target === i
                                                            )
                                                        ].className
                                                    } + ${
                                                centeredIndex && centeredIndex.some((a) => a === i)
                                                    ? ' text-center'
                                                    : ''
                                            }
                                                    `}
                                        >
                                            {components[
                                                components.findIndex((a) => a.target === i)
                                            ].component(obj)}
                                        </td>
                                    ) : (
                                        <td
                                            key={'lineTable-' + key}
                                            className={
                                                centeredIndex && centeredIndex.some((a) => a === i)
                                                    ? 'text-center'
                                                    : ''
                                            }
                                        >
                                            {obj[keys[i]]}
                                        </td>
                                    )
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={head.length} className='text-center'>
                                <span className='text-bold fs-6'>Nenhum registro encontrado!</span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex'>
                    <label className='mr-3 mb-0 d-none d-md-block px-2'>
                        Mostrando de{' '}
                        {page * nPage +
                            1 +
                            ' ao ' +
                            (page * nPage + (data?.data.datatable.data.length || 1)) +
                            ' de ' +
                            data?.data.datatable.iTotalDisplayRecords +
                            ' registro(s)'}
                    </label>
                    {}
                </div>
                <div className='d-flex align-items-center'>
                    <label className='mr-3 mb-0 d-none d-md-block px-4'>
                        Quantidade por página:
                    </label>
                    <select
                        className='form-select form-select-solid w-auto'
                        data-placeholder='Select an option'
                        value={nPage}
                        onChange={(e) => setNPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                </div>
            </div>
            <Pagination
                max={maxPage}
                page={page}
                maxVisible={5}
                onChange={(newPage) => setPage(newPage)}
                finalPage
                initialPage
            />
        </>
    )
}
