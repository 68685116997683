import { TextInputMoneyMask } from '../form/TextInputMoneyMask'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export function SearchMaxMoney({onChange, onBlur}: Props) {
    return (
        <div className='col-12 col-lg-2 mb-5'>
            <TextInputMoneyMask
                label='Valor máximo'
                placeholder='Ex: R$10'
                name='max_money'
                onChange={onChange}
                onBlur={onBlur}
            />
        </div>
    )
}
