import { TextInput } from '../form'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export function SearchDateAt({onChange, onBlur}: Props) {
    return (
        <div className='col-12 col-lg-3 mb-5'>
            <TextInput
                label='Data'
                type='date'
                name='date_at'
                onChange={onChange}
                onBlur={onBlur}
                placeholder={'Entre com a data'}
            />
        </div>
    )
}