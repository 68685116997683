interface PropsPagination {
    max: number
    page: number
    maxVisible: number
    onChange: (arg: number) => void
    finalPage: boolean
    initialPage: boolean
}
export default function Pagination({
    max = 0,
    page = 0,
    maxVisible = 5,
    onChange = (arg: number) => {},
    finalPage = false,
    initialPage = false,
}: PropsPagination) {
    function handleChangePage(newPage: number) {
        onChange(newPage)
    }

    function NumbersGenerator() {
        let allList = []
        let begin = 0
        if (page + 1 > Math.ceil(maxVisible / 2)) {
            begin = page + 1 - Math.ceil(maxVisible / 2)
        }
        if (begin + 1 > max - maxVisible) {
            begin = max - maxVisible
        }
        if (begin < 0) {
            begin = 0
        }
        if (maxVisible + begin > max) {
            maxVisible = max
        }
        if (initialPage && begin) {
            allList.push(
                <li className={`page-item ${page === 0 && 'disabled'}`} key='beginPage'>
                    <button onClick={() => handleChangePage(0)} className='page-link'>
                        {1}
                    </button>
                </li>,
                <li className={`page-item disabled`} key='...1'>
                    <button className='page-link'>...</button>
                </li>
            )
        }
        for (let i = begin; i < maxVisible + begin; i++) {
            allList.push(
                <li className={`page-item ${page === i && 'active'}`} key={'buttonPage-' + (i + 1)}>
                    <button onClick={() => handleChangePage(i)} className='page-link'>
                        {i + 1}
                    </button>
                </li>
            )
        }
        if (finalPage && begin < max - maxVisible) {
            allList.push(
                <li className={`page-item disabled`} key='...2'>
                    <button className='page-link'>...</button>
                </li>,
                <li className={`page-item ${page === max && 'disabled'}`} key='maxPage'>
                    <button onClick={() => handleChangePage(max - 1)} className='page-link'>
                        {max}
                    </button>
                </li>
            )
        }

        return allList
    }
    function nextPage() {
        if (page < max - 1) {
            handleChangePage(page + 1)
        }
    }
    function previousPage() {
        if (page > 0) {
            handleChangePage(page - 1)
        }
    }
    return (
        <ul className='pagination'>
            <li className='page-item previous'>
                <button onClick={() => previousPage()} className='page-link'>
                    {/* <i className="previous"></i> */}
                    Anterior
                </button>
            </li>
            {NumbersGenerator()}
            <li className='page-item next'>
                <button onClick={() => nextPage()} className='page-link'>
                    {/* <i className="next"></i> */}
                    Próximo
                </button>
            </li>
        </ul>
    )
}
