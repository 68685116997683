export function ButtonSearch() {
    return (
        <div className='col w-100 d-flex justify-content-end mb-5'>
            <button
                type='submit'
                className='btn btn-primary px-6 w-auto align-self-end'
            >
                Procurar
            </button>
        </div>
    )
}
